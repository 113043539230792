<template>
  <div class="login">
    <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
      <div class="card card0 border-0" style="border-radius:10px;">
        <div class="row d-flex">
          <div class="col-lg-7">
            <div class="card1 pb-5">
                <div class="row">
                  <img src="/logo.png" class="logo" />
                </div>
                <div
                  class="row px-3 justify-content-center mt-4 mb-5"
                >
                  <img src="/nuta.png" class="image" style="padding-top:100px" />
                </div>
            </div>
          </div>
          <div class="col-lg-5 border-line">
            <form @submit.prevent="userLogin">
              <div class="card2 card border-0 px-4 py-5">
                <div class="row">
                  <div class="col-lg-12 text-center">
                      <h4><b>LOGIN</b></h4>
                  </div>
                </div>
                <div class="row px-3" style="padding-top:5%">
                  <label class="mb-1 col-lg-12 text-center" style="padding-bottom: 20px">
                    Silahkan isikan berikut untuk login ke kasir outlet {{outletName}} - {{outletAddress}}
                  </label>
                  
                  <input
                    class="form-control"
                    v-model="form.username"
                    type="text"
                    name="username"
                    placeholder=""
                  />
                  <label class="floating">Nama User</label>
                  <label class="mb-1 col-lg-12 text-left error" v-if="errors">
                    {{ errors }}
                  </label>
                </div>
                <div class="row px-3">
                   <div class="input-group" id="show_hide_password">
                      <input
                      class="form-control"
                        v-model="form.password"
                        type="password"
                        name="password"
                        placeholder=""
                      />
                      <a href="" class="iconhiden"><i class="fa fa-eye-slash py-2 px-1" aria-hidden="true"></i></a>
                  </div>

                  <label class="floating">Password</label>
                  <label class="mb-1 col-lg-12 text-left error2" v-if="errors2">
                    {{ errors2 }}
                  </label>
                </div>
                <div class="row" v-if="updatepassadmin==false">
                  <div class="col-7">
                    User standar : Admin
                  </div>
                  <div class="col-5">
                    Password: 12345
                  </div>
                </div>
                <div class="row mb-7" style="padding-top:20%">
                  <button
                    type="submit"
                    class="col-4 btn btn-green text-center"
                    style="border-radius:5px;margin-left:60%;"
                    :disabled="submitted"
                  >
                    <span v-html="btnLogin"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- komponen modal expired-->
    <ExpiredModal />
    <!-- komponen modal saldo topup-->
    <SaldoModal />
  </div>
</template>
<script>
import {mapGetters, mapActions } from "vuex";
import $ from 'jquery';
import ExpiredModal from "@/components/konfirmasi_modal/ExpiredModal.vue";
import SaldoModal from "@/components/konfirmasi_modal/SaldoModal.vue";

export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      submitted: false,
      errors: null,
      errors2: null,
      updatepassadmin:false,
      btnLogin:"Login"
    };
  },
  components: {
    ExpiredModal,
    SaldoModal
  },
  mounted() {
    this.cekpasswordadmin();
    $(document).ready(function () {
        $("#show_hide_password a").on("click", function (event) {
          event.preventDefault();
          if ($("#show_hide_password input").attr("type") == "text") {
            $("#show_hide_password input").attr("type", "password");
            $("#show_hide_password i").addClass("fa-eye-slash");
            $("#show_hide_password i").removeClass("fa-eye");
          } else if ($("#show_hide_password input").attr("type") == "password") {
            $("#show_hide_password input").attr("type", "text");
            $("#show_hide_password i").removeClass("fa-eye-slash");
            $("#show_hide_password i").addClass("fa-eye");
          }
        });
      });

  },
  computed: {
    ...mapGetters(["outletName","outletAddress"]),
  },
  methods: {
    ...mapActions(["loginUser"]),
     // cek passwordadmin
    cekpasswordadmin(){
        var passwordadmin = localStorage.getItem("usertablet") ? JSON.parse(localStorage.getItem("usertablet")) : null
        passwordadmin = passwordadmin.filter((item) => {
            return (item.Username == "Admin" && item.Password == 12345);
        });
        if(passwordadmin.length == 0){
             this.updatepassadmin = true
        }
    },
    userLogin() {
      this.submitted = true;
      this.btnLogin = "<div class='fa fa-circle-notch fa-spin'></div>";
      this.$store
        .dispatch("loginUserKasir", this.form)
        .then((response) => {
          if (response.status == "OK") {
            this.submitted = false;
            this.$store
            .dispatch("cekopenoutlet")
            .then((result) => {
                if (result==true){
                    this.$router.push({name: 'Kasir'})
                }
                else{
                    this.$router.push({name: 'Open Outlet'})
                }
                
            })
          } else {
            if(this.form.username==""){
                this.errors = "Nama user tidak boleh kosong";
                this.errors2 ="";
                this.submitted = false;
            } else if(this.form.password==""){
                this.errors2 = "Password tidak boleh kosong";
                this.errors ="";
                this.submitted = false;
            } else{
               if (response.message=="Password salah"){
                  this.errors2 = response.message;
                  this.errors ="";
                  this.submitted = false;
               } else if (response.message=="NONAKTIF"){
                  $("#modalExpired").modal("show");
                  this.submitted = false;
               } else if (response.message=="SALDO TIDAK CUKUP") {
                  $("#modalSaldo").modal("show");
                  this.submitted = false;
               } else{
                  this.errors = response.message;
                  this.errors2 ="";
                  this.submitted = false;
               }
            }
          }
          this.btnLogin = "Login";
        })
        .catch((error) => {
          this.errors = error.statusText;
          this.submitted = false;
          this.btnLogin = "Login";
        });
    },
  },
};
</script>
<style scoped>
body {
  color: #000;
  overflow-x: hidden;
  height: 100%;
  background-repeat: no-repeat;
}

.card0 {
  box-shadow: 0px 3px 8px 0px #757575;
  border-radius: 0px;
}

.card2 {
  margin: 0px 40px;
}

.logo {
  width: 150px;
  height:30px;
  transform: translateX(30px) translateY(21px);
}

.image {
  width: 650px;
  height: 250px;
}

.border-line {
  border-left: 1px solid #eeeeee;
  margin-top:10px;
  margin-bottom:10px;
}

.or {
  width: 10%;
  font-weight: bold;
}

.text-sm {
  font-size: 14px !important;
}

::placeholder {
  color: #bdbdbd;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

::-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

input,
textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid rgb(148, 148, 148);
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #000000;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus,
textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #00ae2b;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

.btn-green {
  background-color: #00ae2b;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}

.btn-green:hover {
  background-color: #019b28;
  cursor: pointer;
}

.bg-blue {
  color: #fff;
  background-color: #1a237e;
}
.floating {
    font-size: 14px;
    transform: translateY(-250%) translateX(10%);
    background:#fff;
}
.error {
    font-size: 0.8rem;
    transform: translateY(-150%) translateX(0%);
    color:red;
}
.error2 {
    font-size: 0.8rem;
    transform: translateY(-150%) translateX(0%);
    color:red;
}
.iconhiden{
    position: absolute;
    right: 4px;
    width:30px;
    z-index: 10;
    top:3px;
    height:35px; 
}
@media screen and (max-width: 991px) {
  .logo {
    margin-left: 0px;
  }

  .image {
    width: 300px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }

  .card2 {
    border-top: 1px solid #eeeeee !important;
    margin: 0px 15px;
  }
 .floating {
    font-size: 0.8rem;
    transform: translateY(-290%) translateX(10%);
    background:#fff;
  }
}
</style>